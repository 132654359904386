.news-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  background-color: #f5f6f7;
  padding: 0;
}

@media screen and (max-width: 767px) {
  .news-list {
    grid-template-columns: 1fr;
    row-gap: 10px;
    column-gap: 0;
  }
}
