.spinner {
  display: block;
  width: 50px;
  height: 50px;
  border: 1px solid #444;
  border-bottom-color: #888;

  border-radius: 50%;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;

  animation: spin 0.75s infinite linear;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
