@font-face {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("roboto-slab-v24-latin-regular.woff") format("woff"),
    url("roboto-slab-v24-latin-regular.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto Slab";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("roboto-slab-v24-latin-700.woff") format("woff"),
    url("roboto-slab-v24-latin-700.woff2") format("woff2");
}
