.saved-news__container {
  padding: 62px 104px;
  background-color: #f5f6f7;
}

@media screen and (max-width: 1500px) {
  .saved-news__container {
    padding: 32px 40px;
  }
}

@media screen and (max-width: 767px) {
  .saved-news__container {
    padding: 24px 16px 12px 16px;
  }
}
