.about-me {
  display: flex;
  padding: 80px 0 80px 104px;
}

.about-me__picture {
  background-image: url("../../images/author.jpeg");
  height: 464px;
  width: 464px;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
}

.about-me__bio {
  padding-left: 56px;
  width: 600px;
}

.about-me__title {
  font-family: "Roboto Slab";
  font-weight: 400;
  font-size: 40px;
  line-height: 46px;
  margin: 51px 0 24px 0;
}

.about-me__paragraphs {
  display: flex;
  flex-flow: column;
  row-gap: 24px;
}

.about-me__paragraph {
  font-family: "Roboto";
  font-size: 18px;
  line-height: 24px;
  margin: 0;
}

@media screen and (max-width: 1500px) {
  .about-me {
    padding: 40px 40px 61px 40px;
  }
  .about-me__picture {
    height: 232px;
    width: 232px;
    margin-top: 21px;
  }
  .about-me__title {
    font-size: 30px;
    line-height: 40px;
    margin-top: 0;
  }
  .about-me__bio {
    width: 424px;
    padding-left: 32px;
  }
}

@media screen and (max-width: 767px) {
  .about-me {
    padding: 32px 24px 0 24px;
    display: flex;
    flex-flow: column;
  }

  .about-me__picture {
    height: 272px;
    width: 272px;
  }

  .about-me__bio {
    width: -webkit-fill-available;
    padding: 0;
    margin-top: 24px;
    margin-bottom: 128px;
  }
}
