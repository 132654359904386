@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("roboto-v30-latin-regular.woff") format("woff"),
    url("roboto-v30-latin-regular.woff2") format("woff2");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("roboto-v30-latin-500.woff") format("woff"),
    url("roboto-v30-latin-500.woff2") format("woff2");
}
