.search {
  background-color: #f5f6f7;
  justify-content: center;
  align-items: center;
  padding: 80px 104px;
}

.search__success {
  display: none;
}

.search__success-title {
  font-family: "Roboto Slab";
  font-size: 40px;
  line-height: 46px;
  margin: 0 0 64px 0;
  font-weight: 400;
}

.search__container {
  background-color: #f5f6f7;
  padding: 64px 0;
}

.search__show-more {
  background-color: white;
  border-radius: 80px;
  padding: 20px 98.5px;
  border: 0;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin-left: 472px;
  margin-top: 64px;
}

.search__show-more:hover {
  background-color: #e8e8e8;
}

.search__fail {
  display: none;
  flex-flow: column;
  align-items: center;
  width: 215px;
  margin: 0 auto;
}

.search__fail-icon {
  background-image: url("../../images/not-found.svg");
  height: 82.5px;
  width: 82.5px;
  margin: 0 auto 31.5px auto;
}

.search__fail-title {
  font-family: "Roboto Slab";
  font-size: 26px;
  line-height: 30px;
  margin: 0;
  padding-bottom: 16px;
  text-align: center;
}

.search__fail-text {
  font-family: "Roboto";
  font-size: 18px;
  line-height: 24px;
  color: #b6bcbf;
  text-align: center;
  margin: 0;
}

.search__loading {
  display: none;
  flex-flow: column;
  align-items: center;
}

.search__spinner {
  position: relative;
  height: 50px;
  width: 50px;
  margin: 0 auto;
}

.search__loading-text {
  font-family: "Roboto";
  font-size: 18px;
  line-height: 24px;
  color: #b6bcbf;
  text-align: center;
}

.search__block_opened {
  display: block;
}

@media screen and (max-width: 1500px) {
  .search {
    padding: 40px;
  }

  .search__success-title {
    font-size: 30px;
    line-height: 34px;
    margin-bottom: 32px;
  }

  .search__container {
    padding: 32px 0;
  }

  .search__show-more {
    margin-left: 232px;
    padding: 16px 74px;
    margin-top: 32px;
  }
}

@media screen and (max-width: 767px) {
  .search {
    padding: 32px 16px 24px 16px;
  }

  .search__show-more {
    padding: 16px 98px;
    margin-top: 16px;
    margin-left: 0;
  }
}
