.navbar {
  width: -webkit-fill-available;
  position: absolute;
  height: 80px;
  background: rgba(196, 196, 196, 0.01);
  display: flex;
  color: white;
  justify-content: space-between;
  align-items: center;
  pointer-events: fill;
}

.navbar_black {
  box-shadow: inset 0px -1px 0px #d1d2d6;
}

.navbar__borders {
  box-shadow: inset 0px -1px 0px rgba(255, 255, 255, 0.2);
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  pointer-events: none;
}

.navbar__hamburger {
  height: 24px;
  width: 24px;
  background-image: url("../../images/hamburger.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: transparent;
  border: 0;
  display: none;
}

.navbar__hamburger_type_black {
  background-image: url("../../images/hamburger-black.svg");
}

.navbar__hamburger_opened {
  transform: rotate(45deg);
  background-image: url("../../images/plus.svg");
}

.navbar__header {
  font-family: "Roboto Slab";
  font-size: 20px;
  font-weight: 700;
  margin: 0;
  cursor: default;
  margin-left: 104px;
}

.navbar__header_black {
  color: black;
}

.navbar__block {
  display: flex;
  column-gap: 54px;
  align-items: center;
  font-family: "Roboto";
  margin-right: 104px;
}

.navbar__link {
  margin: 0;
  color: white;
  text-align: center;
  line-height: 24px;
  font-size: 18px;
  text-decoration: none;
  font-weight: 500;
  background-color: transparent;
}

.navbar__link_type_black {
  color: black;
}

.navbar__link_type_active {
  border-bottom: 3px solid white;
  padding-bottom: 25px;
  margin-top: 25px;
}

.navbar__link_type_sign-in {
  border: 1px solid white;
  border-radius: 100px;
  padding: 12px 58px;
  cursor: pointer;
  font-family: "Roboto";
}

.navbar__link_type_logout {
  border-radius: 100px;
  border: 1px solid white;
  padding: 12px 19px;
  font-family: "Roboto";
  display: flex;
  column-gap: 16px;
  cursor: pointer;
}

.navbar__link_type_border-black {
  border-color: black;
}

.navbar__logout-icon {
  height: 24px;
  width: 24px;
  background-image: url("../../images/logout-white.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.navbar__logout-icon_black {
  height: 24px;
  width: 24px;
  background-image: url("../../images/logout-black.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media screen and (max-width: 1500px) {
  .navbar {
    height: 66px;
  }

  .navbar__header {
    margin-left: 40px;
  }

  .navbar__link {
    font-size: 16px;
  }

  .navbar__link_type_sign-in {
    padding: 8px 46px;
  }

  .navbar__link_type_logout {
    padding: 8px 15px;
  }

  .navbar__link_type_active {
    margin-top: 20px;
    padding-bottom: 18px;
  }

  .navbar__block {
    column-gap: 16px;
    margin-right: 40px;
  }
}

@media screen and (max-width: 767px) {
  .navbar {
    height: 56px;
    z-index: 0;
  }

  .navbar__block {
    position: absolute;
    max-height: 0;
    overflow: hidden;
    background-color: black;
    display: flex;
    flex-flow: column;
    left: 0;
    top: 0;
    align-items: flex-start;
    z-index: -1;
    transition: max-height 0.5s, padding 0.5s;
    padding: 0 16px;
    margin-right: 0;
  }

  .navbar__header {
    margin-left: 16px;
    transition: color 0.5s 0.1s;
    font-size: 16px;
    line-height: 24px;
  }

  .navbar__link {
    line-height: 56px;
    margin: 0;
    padding: 0;
    border: 0;
  }

  .navbar__block_opened {
    max-height: 500px;
    padding: 72px 16px 24px 16px;
    width: -webkit-fill-available;
  }

  .navbar__hamburger {
    display: block;
    margin-right: 16px;
  }

  .navbar__link_type_sign-in {
    padding: 16px 115px;
    border: 1px solid white;
    font-size: 18px;
    line-height: 24px;
    margin-top: 22px;
  }

  .navbar__link_type_logout {
    padding: 16px 103px;
    border: 1px solid white;
    font-size: 18px;
    line-height: 24px;
    margin-top: 22px;
  }
}
